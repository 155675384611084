import React from 'react';
// import Jurski from '../assets/Jurski.jpg';
import Piotr from '../assets/piotr.jpg';

const About = () => {

  return (
    <div name='about' className='about-pj w-full h-screen max-h-full bg-[#fff] text-gray-300 break-after-column'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid flex sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 sm:px-10 sm:h-full md:h-auto lg:h-auto'> {/* max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full */}
          <div className='sm:item-center md:text-right sm:pb-12 md:pb-8 lg:pb-12 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-[#9A1AF8]'>
              Get to Know Me
            </p>
          </div>
          <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-6 px-10'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>Hi. I'm <span className='text-[#9A1AF8]'>Piotr</span>,</p>
              <p>Software Engineer from Brooklyn, NY</p>
            </div>
            <div className='md:hidden lg:hidden'>
              <img src={Piotr} alt='Piotr Jurski Logo' style={{ width: '160px' }} />
            </div>
            <div>
              <p>I am passionate about building excellent front-end applications that improve the lives of those around me. I specialize in many programming languages and DevOps cloud solutions. Over the years I have deployed many innovate projects that helped my organization thrive and succeed. I am highly motivated, hard working, efficient, and enthusiastic individual. I am a great team player and always have a positive attitude. Please reach out below to find out more!</p>
            </div>
          </div>
      </div>
    </div>
  );
};

export default About;
